export default function Privacy() {
  return (
    <>
      <main className="p-10">
        <div>
          <h1 className="font-bold mb-1">Our Privacy Policy</h1>
          <p>
            Thank you for choosing to be part of Lyme Ghana Limited. We are
            committed to protecting your personal information and your right to
            privacy. If you have any questions or concerns about this privacy
            notice, or our practices with regards to your personal information,
            please reach out to us at
            <span className="font-bold"> ceafive@gmail.com</span>.<br />
            <br /> We appreciate that you are trusting us with your personal
            information. We take your privacy very seriously. In this privacy
            notice, we seek to explain to you in the clearest way possible what
            information we collect, how we use it and what rights you have in
            relation to it. We hope you take some time to read through it
            carefully, as it is important. If there are any terms in this
            privacy notice that you do not agree with, please discontinue use of
            our Services immediately.
            <br />
            <br /> This privacy notice applies to all information collected
            through our Services (this includes our App), as well as, any
            related services, sales, marketing or events.
          </p>{" "}
          <br />
          <p className="font-bold">
            Please read this privacy notice carefully as it will help you
            understand what we do with the information that we collect.
          </p>
          <br /> <br />
          <h1 className="font-bold">TABLE OF CONTENTS</h1>
          <ul className="list-inside list-decimal">
            <li className="my-2">WHAT INFORMATION DO WE COLLECT? </li>
            <li className="my-2">
              WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </li>
            <li className="my-2">
              DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </li>
            <li className="my-2">
              IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </li>
            <li className="my-2">HOW LONG DO WE KEEP YOUR INFORMATION?</li>
            <li className="my-2">DO WE COLLECT INFORMATION FROM MINORS?</li>
            <li className="my-2">WHAT ARE YOUR PRIVACY RIGHTS?</li>
            <li className="my-2">CONTROLS FOR DO-NOT-TRACK FEATURES</li>
            <li className="my-2">
              DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </li>
            <li className="my-2">DO WE MAKE UPDATES TO THIS NOTICE?</li>
            <li className="my-2">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
            <li className="my-2">
              HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?
            </li>
          </ul>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              1. WHAT INFORMATION DO WE COLLECT?
            </h1>

            <p className="text-brandIceberg my-2">
              Personal information you disclose to us
            </p>
            <p className="italic my-2">
              <span className="font-bold ">In Summary: </span>
              We collect personal information that you primarily provide to your
              estate management and provide to us through the app.
            </p>
            <p>
              We collect personal information that you voluntarily provide to us
              when you sign on the App, express an interest in obtaining
              information about us or our products and Services, when you
              participate in activities on the App. The personal information
              that we collect depends on the context of your interactions with
              us and the App, the choices you make and the products and features
              you use. The personal information we collect may include the
              following: Your name and phone number, gender, home owner or
              Tenant status, GPS coordinates of your house/property, payment
              details from your digital cards (VISA,MASTERCARD etc), mobile
              money platforms.
            </p>

            <p className="text-brandIceberg my-2">
              Information automatically collected
            </p>
            <p className="italic my-2">
              <span className="font-bold ">In Summary: </span>
              Some information — such as your Internet Protocol (IP) address
              and/or browser and device characteristics — is collected
              automatically when you visit our App.{" "}
            </p>
            <p>
              We automatically collect certain information when you visit, use
              or navigate the App. This information does not reveal your
              specific identity (like your name or contact information) but may
              include device and usage information, such as your IP address,
              browser and device characteristics, operating system, language
              preferences, referring URLs, device name, country, location,
              information about how and when you use our App and other technical
              information. This information is primarily needed to maintain the
              security and operation of our App, and for our internal analytics
              and reporting purposes.
              <br />
              <br /> Like many businesses, we also collect information through
              cookies and similar technologies.
            </p>

            <p className="text-brandIceberg my-2">
              Information collected through our App
            </p>
            <p className="italic my-2">
              <span className="font-bold ">In Summary: </span>
              We collect information regarding your mobile device, push
              notifications, when you use our App.
            </p>
            <p>
              If you use our App, we also collect the following information:
              <ul className="list-inside">
                <li className="my-2">
                  <p>
                    <span className="font-bold italic">
                      Mobile Device Access.{" "}
                    </span>
                    We may request access or permission to certain features from
                    your mobile device, including your mobile device's calendar,
                    camera, microphone, contacts, storage, and other features.
                    If you wish to change our access or permissions, you may do
                    so in your device's settings.
                  </p>
                </li>
                <li className="my-2">
                  <p>
                    <span className="font-bold italic">
                      Mobile Device Data.{" "}
                    </span>
                    We automatically collect device information (such as your
                    mobile device ID, model and manufacturer), operating system,
                    version information and system configuration information,
                    device and application identification numbers, browser type
                    and version, hardware model Internet service provider and/or
                    mobile carrier, and Internet Protocol (IP) address (or proxy
                    server). If you are using our App, we may also collect
                    information about the phone network associated with your
                    mobile device, your mobile device’s operating system or
                    platform, the type of mobile device you use, your mobile
                    device’s unique device ID and information about the features
                    of our App you accessed.
                  </p>
                </li>
                <li className="my-2">
                  <p>
                    <span className="font-bold italic">
                      Push Notifications.{" "}
                    </span>
                    We may request to send you push notifications regarding your
                    account or certain features of the App. If you wish to
                    opt-out from receiving these types of communications, you
                    may turn them off in your device's settings.
                  </p>
                </li>
              </ul>
              <p>
                This information is primarily needed to maintain the security
                and operation of our App, for troubleshooting and for our
                internal analytics and reporting purposes.
              </p>
            </p>
          </div>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              2. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
            </h1>

            <p className="italic my-2">
              <span className="font-bold ">In Summary: </span>
              We only share information with your consent, to comply with laws,
              to provide you with services, to protect your rights, or to
              fulfill business obligations
            </p>
            <p>
              We may process or share your data that we hold based on the
              following legal basis:
            </p>

            <p>
              <ul className="list-inside">
                <li className="my-2">
                  <p>
                    <span className="font-bold italic">Consent: </span>: We may
                    process your data if you have given us specific consent to
                    use your personal information for a specific purpose.
                  </p>
                </li>
                <li className="my-2">
                  <p>
                    <span className="font-bold italic">
                      Legitimate Interests:{" "}
                    </span>
                    We may process your data when it is reasonably necessary to
                    achieve our legitimate business interests.
                  </p>
                </li>
                <li className="my-2">
                  <p>
                    <span className="font-bold italic">
                      Performance of a Contract:{" "}
                    </span>
                    Where we have entered into a contract with you, we may
                    process your personal information to fulfill the terms of
                    our contract
                  </p>
                </li>
                <li className="my-2">
                  <p>
                    <span className="font-bold italic">
                      Legal Obligations:{" "}
                    </span>
                    We may disclose your information where we are legally
                    required to do so in order to comply with applicable law,
                    governmental requests, a judicial proceeding, court order,
                    or legal process, such as in response to a court order or a
                    subpoena (including in response to public authorities to
                    meet national security or law enforcement requirements).
                  </p>
                </li>
                <li className="my-2">
                  <p>
                    <span className="font-bold italic">Vital Interests: </span>
                    We may disclose your information where we believe it is
                    necessary to investigate, prevent, or take action regarding
                    potential violations of our policies, suspected fraud,
                    situations involving potential threats to the safety of any
                    person and illegal activities, or as evidence in litigation
                    in which we are involved
                  </p>
                </li>
              </ul>
              <p>
                More specifically, we may need to process your data or share
                your personal information in the following situations:
                <ul className="list-inside">
                  <li className="my-2">
                    <p>
                      <span className="font-bold italic">
                        Business Transfers:{" "}
                      </span>
                      We may share or transfer your information in connection
                      with, or during negotiations of, any merger, sale of
                      company assets, financing, or acquisition of all or a
                      portion of our business to another company.
                    </p>
                  </li>
                  <li className="my-2">
                    <p>
                      <span className="font-bold italic">
                        Google Maps Platform APIs:{" "}
                      </span>
                      We may share your information with certain Google Maps
                      Platform APIs (e.g., Google Maps API, Place API). To find
                      out more about Google’s Privacy Policy, please refer to
                      this link. We obtain and store on your device ('cache')
                      your location for 6 months. You may revoke your consent
                      anytime by contacting us at ceafive@gmail.com
                    </p>
                  </li>
                </ul>
              </p>
            </p>
          </div>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              3. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
            </h1>

            <p className="italic my-2">
              <span className="font-bold ">In Summary: </span>We may We may use
              cookies and other tracking technologies to collect and store your
              information
            </p>
            <p>
              We may use cookies and similar tracking technologies (like web
              beacons and pixels) to access or store information. Specific
              information about how we use such technologies and how you can
              refuse certain cookies is set out in our Cookie Notice.
            </p>
          </div>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              4. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
            </h1>

            <p className="italic my-2">
              <span className="font-bold ">In Summary: </span>We may transfer,
              store, and process your information in countries other than your
              own.
            </p>
            <p>
              Our servers are located in the United States of America. If you
              are accessing our App from outside, please be aware that your
              information may be transferred to, stored, and processed by us in
              our facilities and by those third parties with whom we may share
              your personal information (see "WILL YOUR INFORMATION BE SHARED
              WITH ANYONE?" above), in and other countries.
              <br />
              <br /> If you are a resident in the European Economic Area (EEA)
              or United Kingdom (UK), then these countries may not necessarily
              have data protection laws or other similar laws as comprehensive
              as those in your country. We will however take all necessary
              measures to protect your personal information in accordance with
              this privacy notice and applicable law.
            </p>
          </div>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              5. HOW LONG DO WE KEEP YOUR INFORMATION?
            </h1>

            <p className="italic my-2">
              <span className="font-bold ">In Summary: </span>We keep your
              information for as long as necessary to fulfill the purposes
              outlined in this privacy notice unless otherwise required by law.
            </p>
            <p>
              We will only keep your personal information for as long as it is
              necessary for the purposes set out in this privacy notice, unless
              a longer retention period is required or permitted by law (such as
              tax, accounting or other legal requirements). No purpose in this
              notice will require us keeping your personal information for
              longer than necessary.
              <br />
              <br />
              When we have no ongoing legitimate business need to process your
              personal information, we will either delete or anonymize such
              information, or, if this is not possible (for example, because
              your personal information has been stored in backup archives),
              then we will securely store your personal information and isolate
              it from any further processing until deletion is possible.
            </p>
          </div>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              6. DO WE COLLECT INFORMATION FROM MINORS?
            </h1>

            <p className="italic my-2">
              <span className="font-bold ">In Summary: </span>We do not
              knowingly collect data from or market to children under 18 years
              of age.
            </p>
            <p>
              We do not knowingly solicit data from or market to children under
              18 years of age. By using the App, you represent that you are at
              least 18 or that you are the owner or tenant of the property
              within the estate. If we learn that personal information from
              users less than 18 years of age has been collected, we will
              deactivate the account and take reasonable measures to promptly
              delete such data from our records. If you become aware of any data
              we may have collected from children under age 18, please contact
              us at ceafive@gmail.com.
            </p>
          </div>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              7. WHAT ARE YOUR PRIVACY RIGHTS?
            </h1>

            <p className="italic my-2">
              <span className="font-bold ">In Summary: </span>You may review,
              change, or terminate your account at any time.
            </p>
            <p>
              If you are a resident in the EEA or UK and you believe we are
              unlawfully processing your personal information, you also have the
              right to complain to your local data protection supervisory
              authority. You can find their contact details here:
              <a
                href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
              "
              >
                http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.
              </a>
              If you are a resident in Switzerland, the contact details for the
              data protection authorities are available here:
              <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">
                https://www.edoeb.admin.ch/edoeb/en/home.html.
              </a>
            </p>
            <p className="text-brandIceberg my-2">Account Information</p>
            <p>
              If you would at any time like to review or change the information
              in your account or terminate your account, you can:
            </p>
            <br />
            <br />

            <p className="my-2">
              Log into your account settings and update your user account.
            </p>
            <p>
              Upon your request to terminate your account, we will deactivate or
              delete your account and information from our active databases.
              However, we may retain some information in our files to prevent
              fraud, troubleshoot problems, assist with any investigations,
              enforce our Terms of Use and/or comply with applicable legal
              requirements.
            </p>
            <p>
              <span className="font-bold ">Opting out of email marketing:</span>
              You can unsubscribe from our marketing email list at any time by
              clicking on the unsubscribe link in the emails that we send or by
              contacting us using the details provided below. You will then be
              removed from the marketing email list — however, we may still
              communicate with you, for example to send you service-related
              emails that are necessary for the administration and use of your
              account, to respond to service requests, or for other
              non-marketing purposes. To otherwise opt-out, you may:  Access
              your account settings and update preferences.
            </p>
          </div>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              8. CONTROLS FOR DO-NOT-TRACK FEATURES
            </h1>

            <p>
              Most web browsers and some mobile operating systems and mobile
              applications include a Do-Not-Track ("DNT") feature or setting you
              can activate to signal your privacy preference not to have data
              about your online browsing activities monitored and collected. At
              this stage no uniform technology standard for recognizing and
              implementing DNT signals has been finalized. As such, we do not
              currently respond to DNT browser signals or any other mechanism
              that automatically communicates your choice not to be tracked
              online. If a standard for online tracking is adopted that we must
              follow in the future, we will inform you about that practice in a
              revised version of this privacy notice.
            </p>
          </div>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
            </h1>

            <p className="italic my-2">
              <span className="font-bold ">In Summary: </span>Yes, if you are a
              resident of California, you are granted specific rights regarding
              access to your personal information
            </p>

            <p>
              California Civil Code Section 1798.83, also known as the "Shine
              The Light" law, permits our users who are California residents to
              request and obtain from us, once a year and free of charge,
              information about categories of personal information (if any) we
              disclosed to third parties for direct marketing purposes and the
              names and addresses of all third parties with which we shared
              personal information in the immediately preceding calendar year.
              If you are a California resident and would like to make such a
              request, please submit your request in writing to us using the
              contact information provided below.
              <br />
              <br /> If you are under 18 years of age, reside in California, and
              have a registered account with the App, you have the right to
              request removal of unwanted data that you publicly post on the
              App. To request removal of such data, please contact us using the
              contact information provided below, and include the email address
              associated with your account and a statement that you reside in
              California. We will make sure the data is not publicly displayed
              on the App, but please be aware that the data may not be
              completely or comprehensively removed from all our systems (e.g.
              backups, etc.).
            </p>
          </div>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              10. DO WE MAKE UPDATES TO THIS NOTICE?
            </h1>

            <p className="italic my-2">
              <span className="font-bold ">In Summary: </span>Yes, we will
              update this notice as necessary to stay compliant with relevant
              laws.
            </p>

            <p>
              We may update this privacy notice from time to time. The updated
              version will be indicated by an updated "Revised" date and the
              updated version will be effective as soon as it is accessible. If
              we make material changes to this privacy notice, we may notify you
              either by prominently posting a notice of such changes or by
              directly sending you a notification. We encourage you to review
              this privacy notice frequently to be informed of how we are
              protecting your information.
            </p>
          </div>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              11. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
            </h1>

            <p>
              If you have questions or comments about this notice, you may email
              us at ceafive@gmail.com or come by our office:
            </p>

            <p className="font-bold">
              C8 28A Joyful Road, Lakeside Estate, Accra, 00233, Ghana.
              +233200050072
            </p>
          </div>
          <div className="my-5">
            <h1 className="text-brandIceberg text-xl my-2">
              12. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
              YOU?
            </h1>

            <p>
              Based on the applicable laws of your country, you may have the
              right to request access to the personal information we collect
              from you, change that information, or delete it in some
              circumstances. To request to review, update, or delete your
              personal information, please submit a request directly to us.
            </p>
          </div>
        </div>
      </main>
    </>
  );
}
